<template>
  <div className="animated">
    <i class="icon-menu mr-1"></i>Discount Code List
    <b-btn :to="{ name: 'discountCodeForm' }" size="sm" class="float-right mb-2"><i class="fa fa-plus"></i> Add New</b-btn>
    <hr />
        <v-client-table 
          class="vue-table-table bg-light p-2" 
          name="dataTableDiscountCodeList" 
          :columns="columns" 
          :data="discountCodes" 
          :options="options" 
          :theme="theme" 
          id="table-discounts" 
          :preserveState="preserveState"
        >
          <template slot="child_row" slot-scope="props">
            {{ props.row.description }}
          </template>
          <template slot="id" slot-scope="props">
            <b-form-checkbox
              v-model="actionIds"
              :value="props.row.id"
            ></b-form-checkbox>
          </template>
          <template slot="amount" slot-scope="props">
            {{ formatAmount(props.row.amount, props.row.type) }}
          </template>
          <template slot="dateExpires" slot-scope="props">
            {{ $moment(props.row.dateExpires).format('MM/DD/YYYY') }}
          </template>
          <div slot="actions" slot-scope="props" class="text-center">
            <b-button-group>
              <b-btn size="sm" variant="warning" :to="{ name: 'discountCodeForm', params: { did: props.row.id }}">Edit</b-btn>
              <b-btn size="sm" variant="danger" @click="archiveDiscountCode(props.row.id)">Archive</b-btn>
            </b-button-group>
          </div>
        </v-client-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ClientTable, Event } from 'vue-tables-2-premium'
Vue.use(ClientTable)
export default {
  name: 'DiscountCodes',
  data () {
    return {
      actionIds: [],
      columns: ['id', 'code', 'amount', 'dateExpires', 'actions'],
      options: {
        headings: {
          id: '',
          code: 'Code',
          amount: 'Amount',
          dateExpires: 'Expires',
          actions: 'Action'
        },
        sortable: ['code', 'amount', 'dateExpires'],
        filterable: ['code'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      preserveState: true,
      useVuex: true,
      theme: 'bootstrap4',
      template: 'default',
      viewUid: null
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'discountCodes'
    ])
  },
  methods: {
    ...mapActions({
      getDiscountCodes: 'getDiscountCodes',
      putDiscountCode: 'putDiscountCode'
    }),
    formatAmount (amount, type) {
      return (type === 'percent') ? amount + '%' : '$' + amount
    },
    doGetDiscountCodes () {
      this.getDiscountCodes({
        pagination: false,
        business: this.business.id,
        active: 1
      })
    },
    archiveDiscountCode (id) {
      if (confirm('Archive this discount code?')) {
        this.putDiscountCode({
          id: id,
          active: false
        }).then(() => {
          this.doGetDiscountCodes()
        })
      }
    }
  },
  created () {
    this.doGetDiscountCodes()
  },
  destroyed () {
    this.$store.dispatch('resetDiscountCodes')
  }
}
</script>